import React, { useEffect, useState } from "react";
import LayoutLogged from "../../components/layout/logged";
import WorkflowActivities from '../../components/dialog/workflow-activities';
import api from "../../services/api";
import { Avatar, Checkbox, CircularProgress, Input, ListItemAvatar, ListItemSecondaryAction, ListSubheader, MenuItem, Select, Switch } from "@material-ui/core";
import { Alert, AlertTitle } from "@material-ui/lab";
import GeneralErrors from "../../components/form/general-errors";

import clsx from 'clsx';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import Drawer from '@material-ui/core/Drawer';
import CssBaseline from '@material-ui/core/CssBaseline';
import List from '@material-ui/core/List';
import Divider from '@material-ui/core/Divider';
import IconButton from '@material-ui/core/IconButton';
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft';
import ChevronRightIcon from '@material-ui/icons/ChevronRight';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import FilterListIcon from '@material-ui/icons/FilterList';
import { getCurrentUser, ifNotHaveAccessRedirect } from "../../services/auth";
import WorkflowConfig, { getStepsFromWorkflow } from "../../../workflow-config";
import WorkflowCard from "../../components/dialog/workflow-card";
import { invertColor } from "../../services/color";
import PagePadding from "../../components/layout/page-padding";

import DatePicker, { registerLocale } from "react-datepicker";
import ptBr from "date-fns/locale/pt-BR";
import "react-datepicker/dist/react-datepicker.css";
import styled from "styled-components";

registerLocale("pt-BR", ptBr);

const CustomDatePicker = styled(DatePicker)`
  width: 90px;
  border: 0;
  border-bottom: solid #9e9e9e 1px;
  height: 1.1876em;
  font: inherit;
  font-size: 15px;
  padding: 10px 0;
  background: #f8f8fa;
`;

const drawerWidth = 275;

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
  },
  menuButton: {
    marginRight: theme.spacing(2),
  },
  hide: {
    display: 'none',
  },
  drawer: {
    width: 255,
    flexShrink: 0,
  },
  drawerPaper: {
    width: drawerWidth,
    top: 65,
  },
  drawerHeader: {
    display: 'flex',
    alignItems: 'center',
    padding: theme.spacing(0, 1),
    // necessary for content to be below app bar
    ...theme.mixins.toolbar,
    justifyContent: 'flex-end',
  },
  content: {
    flexGrow: 1,
    padding: theme.spacing(3),
    transition: theme.transitions.create('margin', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    marginLeft: -drawerWidth,
  },
  contentShift: {
    transition: theme.transitions.create('margin', {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen,
    }),
    marginLeft: 0,
  },
  menuHeader: {
    background: 'grey',
    height: 45,
    padding: 10,
    color: 'white',
    borderRadius: '50%',
    cursor: 'pointer',
    top: 110,
    left: 7,
    position: 'fixed',
  }
}));

export default function WorkflowActivitiesPage() {
  const [loadingUsers, setLoadingUsers] = useState(true);
  const [errors, setErrors] = useState();

  const [date, setDate] = useState();
  const [users, setUsers] = useState([]);
  const [usersSelected, setUsersSelected] = useState([]);

  const [filterWorkflow, setFilterWorkflow] = useState(false);
  const [loaded, setLoaded] = useState(false);
  const [filterWorkflowValue, setFilterWorkflowValue] = useState();
  const [filterSteps, setFilterSteps] = useState([]);
  const [apiParams, setApiParams] = useState({ date: new Date });

  const [currentCard, setCurrentCard] = useState(undefined);
  const [currentWorkflow, setCurrentWorkflow] = useState(undefined);

  const classes = useStyles();
  const theme = useTheme();
  const [open, setOpen] = React.useState(true);

  useEffect(() => {
    (async () => {
      setLoadingUsers(true);

      try {
        const response = await api.get(`/user?organization_id=${getCurrentUser().organization_id}`);
        setUsers(response.data.data);
      } catch (e) {
        setErrors(e?.response?.data || 'Erro desconhecido.');
      }

      setLoadingUsers(false);
    })();
  }, []);

  const handleDrawerOpen = () => {
    setOpen(true);
  };

  const handleDrawerClose = () => {
    setOpen(false);
  };

  useEffect(() => {
    const params = {
      date: date ? date : new Date(),
      users: usersSelected,
      workflow: filterWorkflowValue,
      step: filterSteps,
      with_workflow_name: 1,
    };

    setApiParams(params);
    setLoaded(true);
  }, [date, usersSelected, filterWorkflowValue, filterSteps])

  const handleUsersSelected = async (user, checked) => {
    const currentIndex = usersSelected.indexOf(user.id);
    const newChecked = [...usersSelected];

    if (currentIndex === -1) {
      newChecked.push(user.id);
    } else {
      newChecked.splice(currentIndex, 1);
    }

    setUsersSelected(newChecked);
  };

  if (ifNotHaveAccessRedirect('activities.view')) return <></>;

  return (
    <LayoutLogged title="Atividades">
      <PagePadding>
        <div className={classes.root}>
          <CssBaseline />
          <div className={classes.menuHeader} onClick={handleDrawerOpen}>
            <a><ChevronRightIcon /></a>
          </div>

          <Drawer
            className={classes.drawer}
            variant="persistent"
            anchor="left"
            open={open}
            classes={{
              paper: classes.drawerPaper,
            }}
          >
            <div className={classes.drawerHeader}>
              <IconButton onClick={handleDrawerClose}>
                {theme.direction === 'ltr' ? <ChevronLeftIcon /> : <ChevronRightIcon />}
              </IconButton>
            </div>
            <Divider />

            <List
              subheader={
                <ListSubheader component="div" id="nested-list-options">
                  Opções
              </ListSubheader>
              }
            >
              <ListItem style={{
                height: 300
              }}>
                <ListItemSecondaryAction>
                  <CustomDatePicker
                    selected={date}
                    onChange={(value) => setDate(value)}
                    dateFormat="dd/MM/yyyy"
                    placeholderText="dd/mm/aaaa"
                    locale="pt-BR"
                    inline
                  />
                </ListItemSecondaryAction>
              </ListItem>

              <ListItem
                onClick={() => setFilterWorkflow(!filterWorkflow)}
                button
              >
                <ListItemIcon>
                  <FilterListIcon />
                </ListItemIcon>
                <ListItemText primary="Processo" />
                <ListItemSecondaryAction>
                  <Switch
                    edge="end"
                    checked={filterWorkflow}
                    onChange={(event) => {
                      setFilterWorkflow(event.target.checked);
                      setFilterWorkflowValue(undefined);
                      setFilterSteps([]);
                    }}
                  />
                </ListItemSecondaryAction>
              </ListItem>

              {filterWorkflow && (
                <ListItem>
                  <ListItemText primary={(
                    <Select
                      value={filterWorkflowValue}
                      onChange={(event) => setFilterWorkflowValue(event.target.value)}
                      fullWidth
                    >
                      {Object.keys(WorkflowConfig.workflows).map(key => {
                        const workflow = WorkflowConfig.workflows[key];

                        return <MenuItem value={workflow.id}>{workflow.name}</MenuItem>
                      })}
                    </Select>
                  )} />
                </ListItem>
              )}

              {filterWorkflowValue && (
                <ListItem>
                  <ListItemText primary={(
                    <Select
                      value={filterSteps}
                      onChange={(event) => setFilterSteps(event.target.value)}
                      input={<Input />}
                      multiple
                      fullWidth
                    >
                      {getStepsFromWorkflow(filterWorkflowValue).map(step => {
                        return <MenuItem value={step.id}>{step.label}</MenuItem>
                      })}
                    </Select>
                  )} />
                </ListItem>
              )}

              <Divider />

              <List
                subheader={
                  <ListSubheader component="div" id="nested-list-subheader">
                    Usuários
                </ListSubheader>
                }
              >
                {loadingUsers ? <CircularProgress /> : (
                  <>
                    {users.map((currentUser, index) => (
                      <ListItem
                        onClick={() => handleUsersSelected(currentUser, !usersSelected)}
                        button
                      >
                        <ListItemAvatar>
                          <Avatar style={{ background: currentUser.color, color: currentUser.color ? invertColor(currentUser.color) : null }}>{currentUser.name.charAt(0)}</Avatar>
                        </ListItemAvatar>
                        <ListItemText secondary={currentUser.name} />
                        <ListItemSecondaryAction>
                          <Checkbox
                            edge="end"
                            onChange={(event) => handleUsersSelected(currentUser, event.target.checked)}
                            checked={usersSelected?.includes(currentUser.id)}
                          />
                        </ListItemSecondaryAction>
                      </ListItem>
                    ))}
                  </>
                )}
              </List>

              <ListItem><br /><br /><br /></ListItem>
            </List>
          </Drawer>
          <main
            className={clsx(classes.content, {
              [classes.contentShift]: open,
            })}
            style={{ padding: 0, width: '99%' }}
          >
            {errors && (
              <>
                <Alert severity="error">
                  <AlertTitle>Um erro aconteceu</AlertTitle>
                  <GeneralErrors errors={errors} />
                </Alert>
                <br />
              </>
            )}

            {currentCard && (
              <WorkflowCard
                card={currentCard}
                open={currentCard ? true : false}
                currentWorkflow={currentWorkflow}
                onClose={() => {
                  setCurrentCard(undefined);
                  setCurrentWorkflow(undefined);
                }}
              />
            )}

            {loaded && (
              <WorkflowActivities
                api="/activities"
                apiParams={apiParams}
              />
            )}
          </main>
        </div>
      </PagePadding>
    </LayoutLogged>
  );
}